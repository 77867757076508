import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import CONFIG from "../../app/config";

interface SessionState {
    sxp: number, // session expiration timestamp
    sst: number, // session start timestamp
    set: boolean, // session set
    prq: boolean, // pin code required
    pst: boolean, //pin code set
    rcn: boolean, // need rescan
    nltd: boolean, // not limited mode after refreshing this is undefined
}

const initialState: SessionState = {
    sxp: 0,
    sst: 0,
    set: false,
    prq: false,
    pst: false,
    rcn: false,
    nltd: true
}

export const sessionSlice = createSlice({
    name: 'sessionSlice',
    initialState,
    reducers: {
        initSession: (state) => {
            let now = new Date().getTime();
            let ex = now + CONFIG.SESSION_DURATION;
            if(!state.set) {
                state.set = true;
            }
            state.sxp = ex;
            state.sst = now;
            state.rcn = false
        },
        setPinStatus: (state, action: PayloadAction<{prq: boolean, pst: boolean}>) => {
            state.prq = action.payload.prq;
            state.pst = action.payload.pst;
        },
        expireSession: state => {
            state.set = false;
            state.sxp = 0;
            state.sst = 0;
            state.prq = false;
            state.pst = false;
        },
        setRescanStatus: (state, action: PayloadAction<boolean>) => {
            state.rcn = action.payload
        },
        setSessionLtdmd: (state, action: PayloadAction<boolean>) => {
            state.nltd = action.payload
        }
    }
})

export default sessionSlice.reducer;
export const {initSession, expireSession, setPinStatus, setRescanStatus,setSessionLtdmd} = sessionSlice.actions;
export const selectSession = (state: RootState) => state.session
export const selectPinStatus = (state: RootState) => { return {prq: state.session.prq, pst: state.session.pst}}
export const selectLimitedModeStatus = (state: RootState) => state.session.nltd
