import {TextP, Title1} from "../../../styled_components/General";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {SubmitHandler, useForm} from "react-hook-form";
import {AppButton, AppInputField} from "../../../styled_components/Core";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {waiterPasswordReset} from "../userRepository";
import {loginWaiter, selectUser} from "../userSlice";
import {initSession} from "../../session/sessionSlice";
import {clearPastOrders} from "../../cart/ordersSlice";
import {Navigate} from "react-router-dom";

interface Inputs {
    password: string,
    passwordConfirm: string
}

const formSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password length should be at least 6 characters")
        .max(12, "Password cannot exceed more than 12 characters"),
    passwordConfirm: Yup.string()
        .required("Confirm Password is required")
        .min(6, "Password length should be at least 6 characters")
        .max(12, "Password cannot exceed more than 12 characters")
        .oneOf([Yup.ref("password")], "Passwords do not match")
});

export function WaiterPassReset() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const {register,watch,trigger, handleSubmit, formState: {errors}} = useForm<Inputs>({
        mode: "onChange",
        resolver: yupResolver(formSchema)
    });
    const password = watch("password");
    useEffect(() => {
        if(password?.length >= 6){
            trigger("passwordConfirm");
        }
    }, [password, trigger]);

    const [loaded, setLoaded] = useState(false);
    const [pending, setPending] = useState(false);

    const onSubmit: SubmitHandler<Inputs> = data => {
        setPending(true);
        if(user.Waiter?.WaitressID){
            waiterPasswordReset(user.Waiter.WaitressID, data.password)
                .then(() => {
                    if(user.Waiter){
                        dispatch(loginWaiter(user.Waiter));
                        dispatch(initSession());
                        dispatch(clearPastOrders());
                        localStorage.setItem('load', JSON.stringify({
                            id: user.Waiter.OutletID,
                            table: user.Waiter.TableID
                        }))
                        setPending(false);
                        setLoaded(true);
                    }
                }).catch(e => {
                    console.log(e);
                setPending(false);
            })
        }

    }

    return (
        <div id="waiter-login-screen">
            <div className="indent mt-40">
                <Title1>User Password Reset</Title1>
            </div>
            {loaded  && <Navigate replace to={"/splash"}/>}
            <div className="indent mt-10">
                <form action="">
                    <TextP>New Password</TextP>
                    <AppInputField type="password" {...register("password")}/>
                    {errors.password && <p className='text-red-600'>{errors.password.message}</p>}

                    <TextP className="mt-4">Confirm Password</TextP>
                    <AppInputField type="password" {...register("passwordConfirm")}/>
                    {errors.passwordConfirm && <p className='text-red-600'>{errors.passwordConfirm.message}</p>}

                </form>
            </div>
            <div className="flex justify-center items-center mt-4 ">
                <AppButton onClick={handleSubmit(onSubmit)}>
                    {pending ?
                        <AiOutlineLoading3Quarters size={20} className="animate-spin" color="white"/> :
                        "Submit"
                    }
                </AppButton>
            </div>
        </div>
    )
}
