import axios from "axios";
import CONFIG from "../../app/config";
import {
    MenuCategoryInterface,
    MenuItemAllergenInterface, MenuItemExtraDetailsInterface,
    MenuItemGalleryInterface, MenuItemInterface
} from "./menuInterface";
import {LocaleInterface} from "../intl/intlSlice";

export interface GetMenuI {
    catalog: Array<MenuCategoryInterface>,
    list: Array<MenuItemInterface>
}
export function getMenu(vendorId: string, localeId: string, userId?: string) {
    return new Promise<GetMenuI>((resolve,reject) => {
        axios.get(`${CONFIG.API_URL}/menu?vendorId=${vendorId}&localeId=${localeId}&userId=${userId}`)
            .then(res => {
                if(res.status === 200) {
                    const menu :GetMenuI = res.data;
                    resolve(menu);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}

export function getMenuItemGallery(itemId: string) {
    return new Promise<Array<MenuItemGalleryInterface>>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/menu/item/gallery?itemId=${itemId}`)
            .then(res => {
                if(res.status === 200) {
                    const gallery :Array<MenuItemGalleryInterface> = res.data;
                    resolve(gallery);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}

export function getMenuItemAllergens(itemId: string, locale: LocaleInterface) {
    return new Promise<Array<MenuItemAllergenInterface>>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/menu/item/allergens?itemId=${itemId}&localeId=${locale.LanguageID}`)
            .then(res => {
                if(res.status === 200) {
                    const allergens :Array<MenuItemAllergenInterface> = res.data;
                    resolve(allergens);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}


export function getMenuItem(itemId: string, locale: LocaleInterface) {
    return new Promise<MenuItemExtraDetailsInterface>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/menu/item?itemId=${itemId}&localeId=${locale.LanguageID}`)
            .then(res => {
                if(res.status === 200) {
                    const result :MenuItemExtraDetailsInterface = res.data;
                    resolve(result);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}
