import React, {useEffect, useState} from "react";
import {Translate} from "../../../intl/translator";
import {CartItem} from "../../../cart/CartItem";

interface ProductCommentsInterface {
    cartItem: CartItem,
    setCartItem: any
}

export function ProductComments({cartItem, setCartItem}: ProductCommentsInterface) {
    const [show, setShow] = useState(false);
    const strings = {
        s_placeholder: Translate('product_notes_comment')
    }

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 100)
    })

    const setComments = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCartItem(cartItem.setNotes(e.target.value));
    }


    return (
        <div className="indent">
            {show &&
                <textarea
                    onChange={setComments}
                    autoFocus={false}
                    className="
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                     bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    mt-10
                    card-modded-bg
                    focus:text-gray-700 focus:bg-white focus:border-black focus:outline-none
                  "

                    rows={4}
                    placeholder={strings.s_placeholder}
                    defaultValue={cartItem.Notes}
                >
            </textarea>
            }

        </div>
    );
}
