import React, {useEffect} from "react";
import {SideScreenContainer} from "../../../../styled_components/Menu";
import {DarkModeSwitch} from "./DarkModeSwitch";
import {ActionButton} from "../../../../styled_components/ActionButton";
import {IoIosClose} from "react-icons/io";
import {LocalePicker} from "./LocalePicker";
import {AppCredits} from "../../../core/components/AppCredits";
import {useBackButtonGuard} from "../../../session/hooks";
import {Translate} from "../../../intl/translator";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {logoutUser, logoutWaiter, selectUser} from "../../../user/userSlice";
import {UserStatus} from "../../../user/userInterface";
import {useNavigate} from "react-router-dom";
import {VisibilityStatusGuard} from "../../../vendor/components/VisibilityStatusGuard";
import {selectSettingsPanelStatus, setSettingsPanelStatus} from "../../../core/panelsSlice";
import {setFavourites} from "../../menuSlice";
import {clearPastOrders} from "../../../cart/ordersSlice";
import {setVendorFavourites} from "../../../vendor/vendorSlice";


export function MenuSideScreen() {
    const strings = {
        s_login: Translate('login'),
        s_logout: Translate('logout'),
    }
    const user = useAppSelector(selectUser);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const show = useAppSelector(selectSettingsPanelStatus)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [show])

    const close = () => {
        dispatch(setSettingsPanelStatus(false));
    }

    const login = () => {
        navigate("/");
    }

    const logout = () => {
        dispatch(logoutUser());
        dispatch(logoutWaiter())
        dispatch(setFavourites([]));
        dispatch(clearPastOrders());
        dispatch(setVendorFavourites([]));
        navigate("/");
        dispatch(setSettingsPanelStatus(false));
    }

    useBackButtonGuard({callback: close, name: "categories-screen"})
    return (
        <SideScreenContainer isActive={show} side="left">
            <div className="flex flex-col h-full">
                <div>
                    <div className="flex py-5 px-5 place-content-between items-center">
                        <div className="flex">
                            <div className="pr-3">
                                <ActionButton onClick={close}>
                                    <IoIosClose size="32"/>
                                </ActionButton>
                            </div>
                            <DarkModeSwitch />
                        </div>
                        {/*@todo extract to separate component*/}
                        {/*@todo Use a User is-verified service*/}
                        {!user.Waiter &&
                            <VisibilityStatusGuard showInVisibilityStatuses={[]} >
                                <div className="text-xl font-medium" onClick={login}>
                                    {strings.s_login}
                                </div>
                            </VisibilityStatusGuard>
                        }
                        {/*@todo Use a User is-verified service*/}
                        {user.Waiter &&
                            <VisibilityStatusGuard showInVisibilityStatuses={[]} >
                                <div className="text-xl font-medium" onClick={logout}>
                                    {strings.s_logout}
                                </div>
                            </VisibilityStatusGuard>
                        }
                    </div>
                    {/*@todo extract to separate component*/}
                    {/*@todo Use a User is-verified service*/}
                    {user.Verified === UserStatus.Verified  &&
                        <div className="py-2 px-5 text-center text-xl font-medium">
                            "Hello, {user.Name}"
                        </div>
                    }

                    <LocalePicker />
                </div>
                <div className="flex-grow"><hr/></div>
                <AppCredits />
            </div>

        </SideScreenContainer>
    );
}
