import CONFIG from "../../../app/config";
import {Navigate} from "react-router-dom";
import React from "react";
import {RegisterScreen} from "./RegisterScreen";

export function RegisterScreenGuard() {
    return (
        <>
            {(!CONFIG.ENABLE_USERS) &&
                <Navigate to="/menu" />
            }
            <RegisterScreen />
        </>
    )
}
