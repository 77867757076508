import React, {useRef} from 'react';
import {SearchField} from "../../../../styled_components/Menu";
import {VendorDetails} from "../../../vendor/components/VendorDetails";
import {IoSearchOutline} from "react-icons/io5";
import {Translate} from "../../../intl/translator";
import StickyMenuHeader from "../StickyHeader/StickyMenuHeader";
import {MenuHero} from "./MenuHero";
import {MenuSideScreen} from "../MenuSideScreen/MenuSideScreen";
import {MenuResultsMemo} from "./MenuResults";
import {CallScreen} from "../CallScreen/CallScreen";
import {ProductScreen} from "../ProductScreen/ProductScreen";
import {MenuSearchScreen} from "../MenuSearchScreen/MenuSearchScreen";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectMenu} from "../../menuSlice";
import {usePinGuard} from "../../../session/hooks";
import {AppCredits} from "../../../core/components/AppCredits";
import {MenuStickyFooter} from "./MenuStickyFooter";
import {MenuOrderAgain} from "./MenuOrderAgain";
import {selectLastOrderCart} from "../../../cart/ordersSlice";
import {VisibilityStatusGuard} from "../../../vendor/components/VisibilityStatusGuard";
import {VendorVisibility} from "../../../vendor/vendorInterface";
import {selectSearchPanelStatus, selectSettingsPanelStatus, setSearchPanelStatus} from "../../../core/panelsSlice";
import {MenuPendingOrderBubble} from "./MenuPendingOrderBubble";
import {SessionExpiredNotification} from "../../../session/components/SessionExpiredNotification";
import {OrderPendingPreviewPopup} from "../../../cart/components/orderStatus/OrderPendingPreviewPopup";
import {isEqual} from "lodash";

export function Menu() {
    usePinGuard();
    const strings = {
        s_placeholder: Translate('search_field_placeholder')
    }
    const dispatch = useAppDispatch();
    const searchRef = useRef<HTMLInputElement>(null)
    const menuItems = useAppSelector(selectMenu,isEqual);
    const lastCart = useAppSelector(selectLastOrderCart,isEqual);
    const searchPanelStatus = useAppSelector(selectSearchPanelStatus,isEqual);
    const settingsPanelStatus = useAppSelector(selectSettingsPanelStatus,isEqual);

    return (
        <VisibilityStatusGuard showInVisibilityStatuses={[
            VendorVisibility.MenuNoPrices,
            VendorVisibility.MenuWithPrices,
            VendorVisibility.OutletInformationOnly
        ]}>
            <div className="menu-screen">
                <StickyMenuHeader />
                <MenuSideScreen />
                <MenuSearchScreen />
                <CallScreen />
                <ProductScreen/>
                <MenuPendingOrderBubble />
                <SessionExpiredNotification />
                <OrderPendingPreviewPopup />
                <div className={settingsPanelStatus || searchPanelStatus ? 'no-scroll-screen' : ''}>
                    <MenuHero />
                    <VendorDetails/>
                    <VisibilityStatusGuard showInVisibilityStatuses={[
                        VendorVisibility.MenuNoPrices,
                        VendorVisibility.MenuWithPrices
                    ]}>
                        <>
                            <SearchField className="mt-5 mb-8">
                                <IoSearchOutline size={22} fontWeight={300}/>
                                <input onFocus={() => dispatch(setSearchPanelStatus(true))}
                                       ref={searchRef} type="text" placeholder={strings.s_placeholder}/>
                            </SearchField>
                            {lastCart &&
                                <VisibilityStatusGuard showInVisibilityStatuses={[]}>
                                    <MenuOrderAgain lastCart={lastCart}/>
                                </VisibilityStatusGuard>
                            }
                            <MenuResultsMemo items={menuItems}/>
                            <div style={{minHeight: '350px'}}></div>
                        </>
                    </VisibilityStatusGuard>
                    <div style={{minHeight: '50px'}}></div>
                    <AppCredits/>
                    <VisibilityStatusGuard showInVisibilityStatuses={[]}>
                        <MenuStickyFooter/>
                    </VisibilityStatusGuard>
                </div>
            </div>
        </VisibilityStatusGuard>

    );
}

export const MenuMemo = React.memo(Menu);
