// take a price from api e.g "6.5" and convert it readable currency format
export const formatCurrency = (price?: number | string, symbol?: string) => {
    if(!symbol) {
        const data = localStorage.getItem('NINA-OFFICE');
        const state = JSON.parse(data ?? '')
        symbol = state?.vendor?.data?.CurrencySymbol ?? '€';
    }
    return symbol + Number(price).toFixed(2);
}

export const formatTimestampToLocalDate = (timestamp: number | string) => {
    const formatting = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: "numeric",
        minute: 'numeric',
        hour12: false,
        timeZone: "Asia/Nicosia",
    };
    return new Intl.DateTimeFormat('en-CY', formatting).format(new Date(timestamp))
}
