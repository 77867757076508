import {GatewayDataI} from "../../cartInterfaces";
import {Cart} from "../../Cart";
import {createPaymentRequestOrder} from "../../cartRepository";
import {addOrderToPastOrders, PastOrderI} from "../../ordersSlice";

/**
 * Abstract Class Gateway.
 *
 * @class Gateway
 */

export class Gateway {
    props: GatewayDataI;
    cart: Cart;
    orderTotal: string;
    accountId: string;

    constructor(props: GatewayDataI) {
        this.props = props;
        this.cart = new Cart(props.cart);
        this.orderTotal = this.cart.displayFormattedPrice();
        this.accountId = props.paymentType.AccountID ?? "0";

    }

    getPaymentRequestID = () => {
        console.log(this.props.order);
        return new Promise<string>((resolve, reject) => {
            createPaymentRequestOrder(this.props.order, this.props.activeLocale.LanguageID)
                .then(res => {
                    const pastOrder: PastOrderI = {
                        VendorId: this.props.vendor.OutletID,
                        OrderId: "0",
                        PaymentRequestID: res.PaymentRequestID,
                        Cart: this.props.cart
                    }
                    this.props.dispatch(addOrderToPastOrders(pastOrder))
                    resolve(res.PaymentRequestID);
                })
                .catch(e => reject(e))
        })
    }

    submit = ()  => {
        this.getPaymentRequestID().then(id => {
            console.log(id)
        })
    }
}
