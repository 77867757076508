import {Translate} from "../../../intl/translator";
import {ActionButton} from "../../../../styled_components/ActionButton";
import {BsChevronLeft} from "react-icons/bs";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {
    selectTable,
    selectVendor,
    selectVendorPaymentMethods,
    setVendorSchedule
} from "../../../vendor/vendorSlice";
import {selectCart} from "../../cartSlice";
import {Order} from "../../Order";
import {createOrder} from "../../cartRepository";
import {selectUser} from "../../../user/userSlice";
import {CheckoutMethod} from "./CheckoutMethod";
import {selectLocale} from "../../../intl/intlSlice";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {UserStatus} from "../../../user/userInterface";
import CONFIG from "../../../../app/config";
import {VendorPaymentMethodI} from "../../../vendor/vendorInterface";
import {addOrderToPastOrders, afterOrderSubmit, PastOrderI} from "../../ordersSlice";
import {canUserOrder} from "../../../menu/menuService";
import {getVendor, getVendorSchedule} from "../../../vendor/vendorRepository";
import {useNavigate} from "react-router-dom";
import {VisibilityStatusGuard} from "../../../vendor/components/VisibilityStatusGuard";
import {PaymentTypes} from "../../cartInterfaces";
import {RoomChargeBottomSheet, RoomChargeBottomSheetState} from "./RoomChargeBottomSheet";
import {AccountChargeBottomSheet, AccountChargeBottomSheetState} from "./AccountChargeBottomSheet";
import {JccHandler} from "../gateways/Jcc/JccHandler";
import {VivaHandler} from "../gateways/VivaWallet/VivaHandler";
import {SaferPayHandler} from "../gateways/SaferPay/SaferPayHandler";
import {WalleeHandler} from "../gateways/Wallee/WalleeHandler";
import {PaymentTermsConditions, PaymentTermsConditionsSheetState} from "./PaymentTermsConditions";
import {JccApiHandler} from "../gateways/JccApi/JccApiHandler";

export function CheckoutScreen() {
    const strings = {
        s_title: Translate('checkout'),
        s_description: Translate('checkout_screen_description'),
    }
    const vendor = useAppSelector(selectVendor);
    const cart = useAppSelector(selectCart);
    const table = useAppSelector(selectTable);
    const user = useAppSelector(selectUser);
    const paymentMethods = useAppSelector(selectVendorPaymentMethods);
    const activeLocale = useAppSelector(selectLocale);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [shopOpen, setShopOpen] = useState(true);
    const [roomChargeSheet, setRoomChargeSheet] = useState<RoomChargeBottomSheetState>({open: false});
    const [accountChargeSheet, setAccountChargeSheet] = useState<AccountChargeBottomSheetState>({open: false});
    const [termsSheet, setTermsSheet] = useState<PaymentTermsConditionsSheetState>({open: false});

    const onCheckout = (paymentType: VendorPaymentMethodI) => {
        setLoading(true);
        let order: Order = new Order(cart);
        order.info.Tableid = parseInt(table?.TableID ?? "1");
        order.info.Scantimestamp = new Date().getTime().toString();
        order.info.Outletid = parseInt(vendor.OutletID);
        order.info.Reguserid = user.Verified !== UserStatus.Verified ? CONFIG.GUEST_USER_ID : user.RegisteredUserID;
        order.info.Ptypeid = parseInt(paymentType.PaymentTypeID);

        if (user.FirebaseToken) {
            order.info.FirebaseToken = user.FirebaseToken;
        }

        if(user.Waiter){
            order.info.Waiterid = user.Waiter.WaitressID;
        }


        let gatewayData = {paymentType, order, navigate, activeLocale, dispatch, vendor, cart, user}

        switch (paymentType.PaymentTypeID) {
            case PaymentTypes.JCC: {
                let gateway = new JccHandler(gatewayData);
                setTermsSheet({open: true, gateway});
                break;
            }
            case PaymentTypes.JCC_API: {
                let gateway = new JccApiHandler(gatewayData);
                setTermsSheet({open: true, gateway});
                break;
            }
            case PaymentTypes.VIVA_WALLET: {
                let gateway = new VivaHandler(gatewayData);
                setTermsSheet({open: true, gateway});
                break;
            }
            case PaymentTypes.SAFER_PAY: {
                let gateway = new SaferPayHandler(gatewayData);
                setTermsSheet({open: true, gateway});
                break;
            }
            case PaymentTypes.WALLEE: {
                let gateway = new WalleeHandler(gatewayData);
                setTermsSheet({open: true, gateway});
                break;
            }
            case PaymentTypes.ROOM_CHARGE: {
                setRoomChargeSheet({open: true, order});
                break;
            }
            case PaymentTypes.HOUSE_ACCOUNT: {
                setAccountChargeSheet({open: true, order});
                break;
            }
            default: {
                submitOrder(order);
                break;
            }
        }

    }

    const submitOrder = (order: Order) => {
        createOrder(order, activeLocale.LanguageID).then((res) => {
            const pastOrder: PastOrderI = {
                VendorId: vendor.OutletID,
                OrderId: res.OrderID,
                Cart: cart
            }
            if(res.OrderID && pastOrder){
                dispatch(addOrderToPastOrders(pastOrder));
                dispatch(afterOrderSubmit(pastOrder));
                document.location.replace("/order");
            }else{
                console.log(res);
            }

        }).catch(e => navigate('/order/failed'))
    }

    useEffect(() => {
        if (
            cart.items.length === 0 ||
            (user.Verified !== UserStatus.Verified && !canUserOrder({user,vendor}))
        ) {
            navigate("/login");
        } else {
            Promise.all([
                getVendor(vendor.OutletID),
                getVendorSchedule(vendor.OutletID)
            ]).then(res => {
                dispatch(setVendorSchedule(res[1]));
                setShopOpen(res[0].IsOpen === "1");
                setLoading(false);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <VisibilityStatusGuard showInVisibilityStatuses={[]}>
            <>
                <div id="checkout-screen" className="min-h-screen">
                    <div className="grid py-5 indent items-center content-center grid-cols-2 grid-rows-1 mb-4">
                        <div className="col-start-1 col-span-1 row-start-1">
                            <ActionButton onClick={() => {
                                navigate("/cart")
                            }}>
                                <BsChevronLeft size="22"/>
                            </ActionButton>
                        </div>
                        <h1 className="text-center row-start-1 col-start-1 col-span-full text-3xl fore-color">
                            {strings.s_title}
                        </h1>
                    </div>
                    {!loading && shopOpen &&
                        <p className="indent text-xl fore-color">{strings.s_description}</p>
                    }

                    {loading &&
                        <div className="h-96 h-px flex justify-center items-center pt-16">
                            <AiOutlineLoading3Quarters size={50} className="animate-spin m-auto" color="var(--theme-primary-color)"/>
                        </div>
                    }
                    {!loading && shopOpen &&
                        <div className="mt-6 indent">
                            {paymentMethods.map((method: VendorPaymentMethodI) => {
                                return <CheckoutMethod key={method.PaymentTypeID} method={method} action={onCheckout}/>
                            })}
                        </div>
                    }

                </div>
                <RoomChargeBottomSheet
                    sheetState={roomChargeSheet}
                    setSheetState={setRoomChargeSheet}
                    setLoading={setLoading}
                    submitOrder={submitOrder}
                />
                <AccountChargeBottomSheet
                    sheetState={accountChargeSheet}
                    setSheetState={setAccountChargeSheet}
                    setLoading={setLoading}
                    submitOrder={submitOrder}
                />

                <PaymentTermsConditions
                    sheetState={termsSheet}
                    setSheetState={setTermsSheet}
                    setLoading={setLoading}
                    submitOrder={submitOrder}
                />



            </>
        </VisibilityStatusGuard>
    )
}
