import CONFIG from "../../../app/config";
import {Navigate} from "react-router-dom";
import {CartScreen} from "./CartScreen";
import React from "react";

export function CartScreenGuard() {
    return (
        <>
            {!CONFIG.ENABLE_CART &&
                <Navigate to="/menu" />
            }
            <CartScreen />
        </>
    )
}
