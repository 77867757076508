export interface UserInterface {
    RegisteredUserID: string,
    RegistrationTypeID?: string,
    PaymentTypeID?: string,
    Name?: string,
    Surname?: string,
    Username?: string,
    Email?: string,
    PhoneNum?: string,
    PhoneCode?: string,
    Verified: string,
    CompletedOrdersCount?: number,
    FirebaseToken?: string,
    Waiter?: WaiterI
}

export interface WaiterI{
    WaitressID: string,
    Name: string,
    SirName: string,
    UserName: string,
    OutletID: string,
    EmployeeID: string,
    TableID: string,
    ResetAtFirstLogin: string,
    Balance?: number,
    LastBalanceUpdate?: string
}

export interface CompanyDepartmentI {
    Name: string,
    Code: string,
    CompanyUserID: string,
    CompanyID: string,
    DepartmentID: string
}

export interface CompanyUserI {
    RegisteredUserID: string,
    Admin: string,
    PhoneNum: string,
    Department: Array<CompanyDepartmentI>
}

export enum UserStatus {
    Verified = "1",
    UnVerified = "0"
}
