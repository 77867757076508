import CONFIG from "../../../../app/config";
import {Navigate} from "react-router-dom";
import React from "react";
import {CheckoutScreen} from "./CheckoutScreen";

export function CheckoutScreenGuard() {
    return (
        <>
            {!CONFIG.ENABLE_CART &&
                <Navigate to="/menu" />
            }
            <CheckoutScreen />
        </>
    )
}
