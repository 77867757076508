import {CustomSwitchContainer, CustomSwitchHandle} from "../../../../styled_components/CustomSwitch";
import React, { useState} from "react";
import {BsMoon, BsSun} from "react-icons/bs";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectMode, setSpecifiedMode} from "../../../theme/themeSlice";

export function DarkModeSwitch() {
    const dispatch = useAppDispatch();
    const mode = useAppSelector(selectMode);
    const [status, setStatus] = useState(mode === 'light');


    const handleChange = () => {
        const newStatus = !status;
        setStatus(newStatus);
        dispatch(setSpecifiedMode(newStatus ? 'light' : 'dark'))
    }


    return (
        <CustomSwitchContainer
            id="dark-mode-switch"
            onClick={handleChange}
            switchOnColor="var(--theme-primary-color)"
            switchOffColor="#333"
            status={status}
        >
            <div>Light Mode</div>
            <CustomSwitchHandle >
                {status && <BsSun />}
                {!status && <BsMoon />}
            </CustomSwitchHandle>
            <div>Dark Mode</div>
        </CustomSwitchContainer>
    );
}
