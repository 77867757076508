import React, {useEffect, useState} from 'react';
import '../splash.css';
import {
    getTableDetails,
    getVendor,
    getVendorButtons, getVendorPaymentMethods,
    getVendorSchedule, getVendorStatus
} from "../../vendor/vendorRepository";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setVendorColor} from "../../theme/themeSlice";
import {
    selectVendor,
    setTableInfo,
    setVendor,
    setVendorButtons, setVendorFavourites, setVendorOpenStatus, setVendorPaymentMethods,
    setVendorSchedule,
    unsetVendor
} from "../../vendor/vendorSlice";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import { Transition } from '@headlessui/react'
import {getMenu} from "../../menu/menuRepository";
import {setFavourites, setMenu, setMenuList} from "../../menu/menuSlice";
import {setItemSelected} from "../../menu/productSlice";
import {getAvailableLocales} from "../../intl/intlRepository";
import {selectLocale, setAvailableLocales} from "../../intl/intlSlice";
import {VendorLoadDataInterface, VendorOperation} from "../../vendor/vendorInterface";
import {setPinStatus, setSessionLtdmd} from "../../session/sessionSlice";
import {Navigate} from "react-router-dom";
import CONFIG from "../../../app/config";
import {prepareUserLastCart, userCompletedOrders, userFavouriteOutlets, userFavouriteProducts} from "../../user/userRepository";
import {selectUser, setUserCompletedOrders, setUserFirebaseToken} from "../../user/userSlice";
import {lastOrder} from "../../cart/cartRepository";
import {addOrderToPastOrders} from "../../cart/ordersSlice";
import {UserStatus} from "../../user/userInterface";
import {getFirebaseToken} from "../../firebase/config";
import { getAnalytics, logEvent } from "firebase/analytics";


export function Splash() {
    const dispatch = useAppDispatch();
    const [preloaded, setPreloaded ] = useState(false);
    const [loaded, setLoaded ] = useState(false);
    const [error, setError] = useState(false);
    const locale = useAppSelector(selectLocale);
    const vendor = useAppSelector(selectVendor);
    const user = useAppSelector(selectUser);
    const analytics = getAnalytics();
  
    const doResets = () => {
        dispatch(setItemSelected(null));
        dispatch(unsetVendor);
        localStorage.removeItem('load');
    }

    useEffect(() => {
        const loadString = localStorage.getItem('load');
        if (loadString) {
            initApp(loadString);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const initApp = (loadString: string) => {
        const loadData : VendorLoadDataInterface = JSON.parse(loadString);
        doResets();
        preloadApp(loadData).then(loadData => {
            loadApp(loadData).then(res => {
            }).catch(e => setError(true));
        }).catch(e => setError(true));
    }

    const preloadApp = (loadData: VendorLoadDataInterface) => {
        return new Promise<VendorLoadDataInterface>((resolve, reject) => {
            Promise.all([
                getVendor(loadData.id),
            ]).then((res) => {
                dispatch(setVendor(res[0]))
                dispatch(setPinStatus({prq: res[0].IsSecretKeyNeeded === "1", pst: false}))
                dispatch(setVendorColor(res[0].Color ?? 'E84D39'))
                setPreloaded(true);
                //redirect if shop is disabled
                if(
                    res[0].OperationID === VendorOperation.Disable
                ){
                    document.location.replace("/");
                }
                setTimeout(() => {
                    resolve(loadData);
                }, 10)

            }).catch(e => reject(e));
        })
    }

    const loadApp = (loadData: VendorLoadDataInterface) => {
        return new Promise((resolve, reject) => {
            const start = performance.now();
            if(CONFIG.ENABLE_FOREGROUND_NOTIFICATIONS_USER_IDS.includes(user.RegisteredUserID)
                && CONFIG.ENABLE_FOREGROUND_NOTIFICATIONS){
                getFirebaseToken().then(token => {
                    dispatch(setUserFirebaseToken(token))
                }).catch(e => {})
            }

            Promise.all([
                Promise.all([
                    getMenu(loadData.id, locale.LanguageID,user.RegisteredUserID),
                    getVendorButtons(loadData.id),
                    getAvailableLocales(),
                    getTableDetails(loadData.table),
                    getVendorSchedule(loadData.id),
                    getVendorPaymentMethods(loadData.id),
                    getVendorStatus(loadData.id),
                ]),
                Promise.all([
                    userFavouriteProducts(user.RegisteredUserID, loadData.id),
                    userFavouriteOutlets(user.RegisteredUserID),
                    userCompletedOrders(user.RegisteredUserID, loadData.id),
                    lastOrder(user.RegisteredUserID, loadData.id),
                ]),
            ]).then(res => {
                dispatch(setMenu(res[0][0].catalog));
                dispatch(setMenuList(res[0][0].list));
                dispatch(setVendorButtons(res[0][1]));
                dispatch(setAvailableLocales(res[0][2]));
                dispatch(setTableInfo(res[0][3]));
                dispatch(setVendorSchedule(res[0][4]));
                dispatch(setVendorPaymentMethods(res[0][5]));
                dispatch(setVendorOpenStatus(res[0][6]))
                dispatch(setFavourites(res[1][0]));
                dispatch(setVendorFavourites(res[1][1]));
                dispatch(setUserCompletedOrders(res[1][2].length));
                if(res[1][3] && user.Verified === UserStatus.Verified){
                    dispatch(addOrderToPastOrders(prepareUserLastCart(res[1][3], res[0][0].list)))
                }
                dispatch(setSessionLtdmd(true));
                setTimeout(() => {
                    setLoaded(true);
                }, getDurationRemaining(performance.now() - start))
            }).catch(e => reject(e));
        })

    }



    return (
        <div>
            {!preloaded && !error &&
                <div className="flex items-center justify-center h-screen">
                    <AiOutlineLoading3Quarters size={50} className="animate-spin" color="var(--theme-primary-color)"/>
                </div>
            }
            <Transition
                show={preloaded && !loaded && !error}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className={"splash-screen"}>
                    {vendor.SplashImage &&
                        <img className={"splash-logo"} src={CONFIG.SPLASH_IMAGES_URL + vendor.SplashImage} alt=""/>
                    }
                    {!vendor.SplashImage &&
                        <img className={"splash-logo"} src="/images/demo/logo.svg" alt=""/>
                    }
                </div>
            </Transition>
            {loaded && !error && vendor.IsSecretKeyNeeded !== "1" && <Navigate replace to="/menu"/>}
            {loaded && !error && vendor.IsSecretKeyNeeded === "1" && <Navigate replace to="/pin"/>}
            {error &&
                <div className="flex items-center justify-center h-screen flex-col">
                    <AiOutlineLoading3Quarters size={50} className="animate-spin" color="var(--theme-primary-color)"/>
                    <br/>
                    <div>error loading...</div>
                </div>

            }
        </div>
    );




}

const getDurationRemaining = (elapsed: number) => {
    const loadDelay = 2500;
    if(elapsed >= loadDelay) {
        return 1;
    }else{
        return loadDelay - elapsed;
    }
}
