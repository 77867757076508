import {LoginScreen} from "./LoginScreen";
import React from "react";
import CONFIG from "../../../app/config";
import {Navigate} from "react-router-dom";

export function LoginScreenGuard() {

    return (
        <>
            {(!CONFIG.ENABLE_USERS) &&
                <Navigate to="/menu" />
            }
            <LoginScreen />
        </>
    )
}
