import styled from "styled-components";
interface CustomSwitchContainerInterface {
    switchOnColor: string;
    switchOffColor: string;
    status: boolean;
}
export const CustomSwitchContainer = styled.div<CustomSwitchContainerInterface>`
  display: inline-flex;
  height: 38px;
  width: 150px;
  overflow: hidden;
  background-color: ${props => props.status ? props.switchOnColor : props.switchOffColor};
  border-radius: 6px;
  align-items: center;
  padding: 0 10px;
  transition: background-color 750ms;
  box-shadow: 0px 0px 5px #d3d1d830;
  & div:first-child {
    color: white;
    min-width: 100px;
    transition: margin-left 500ms;
    font-size: 18px;
    margin-left: ${props => props.status ? '0' : '-100px'};
  }
  & div:last-child {
    color: white;
    min-width: 100px;
    padding-left: 10px;
    font-size: 18px;
  }
`;

export const CustomSwitchHandle = styled.div`
  width: 30px;
  height: 30px;
  aspect-ratio: 1;
  border-radius: 15px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    color: #333;
  }
  
`;
